import { useQuery } from 'react-query'

import { QUERY_KEYS, NotificationsApi } from '@api'
import { Filters } from '@types'

const useNotifications = (filters: Filters) => {
  const { data, refetch } = useQuery([QUERY_KEYS.NOTIFICATIONS, filters], () =>
    NotificationsApi.getAll(filters),
  )
  return { notifications: data?.data?.length ? data.data : [], refetch }
}

export { useNotifications }

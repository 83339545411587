const size = {
  xs: '575px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}
const primaryColor = '#27356C'
const lightGrey = '#c5c5c5'
const red = '#e24c4c'
const darkGreen = '#5f8b52'
const green = '#649E53'
const lightGreen = '#74b362'
const extraLightGrey = '#F5F5F5;'
const white = '#ffffff'
const lightBlue = '#a6b7fb'
const blue = '#344793'
const grey = '#6c757d'

const theme = {
  xs: `(max-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
  '@border-radius-base': '2px',
  '@table-header-bg': 'white',
  '@divider-color': 'black',
  primaryColor,
  lightGrey,
  red,
  darkGreen,
  green,
  lightGreen,
  extraLightGrey,
  white,
  lightBlue,
  blue,
  grey,
}

module.exports = { theme }

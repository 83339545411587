import { BackofficeUser } from '@types'

import { api } from './axios'

// interface GetBackofficeUserResponse {
//   backofficeUser:
// }

const getBackofficeUser = async (id?: number) => {
  const response = await api.get<BackofficeUser>(`/backoffice/users/${id}`)
  return response.data
}

export const BackofficeUsersApi = { getBackofficeUser }

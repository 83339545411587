export enum ErrorEnum {
  GenericError = 'GenericError',
  UNKNOWN = 'UNKNOWN',
  LOGIN_ATTEMPTS_EXCEEDED = 'LOGIN_ATTEMPTS_EXCEEDED',
  INCORRECT_USER_PASSWORD = 'INCORRECT_USER_PASSWORD',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  TEAM_ALREADY_EXISTS = 'TEAM_ALREADY_EXISTS',
  TEAM_MAX_AMOUNT = 'TEAM_MAX_AMOUNT',
  TEAM_DOESNT_EXISTS = 'TEAM_DOESNT_EXISTS',
  TEAM_CANT_PUBLISH = 'TEAM_CANT_PUBLISH',
  TEAM_CANT_PUBLISH_DETAIL = 'TEAM_CANT_PUBLISH_DETAIL',
  TEAM_ALREADY_PUBLISHED = 'TEAM_ALREADY_PUBLISHED',
  TEAM_CANT_PUBLISH_WITHOUT_IMAGE = 'TEAM_CANT_PUBLISH_WITHOUT_IMAGE',
  TEAM_CANT_PUBLISH_WITHOUT_IMAGE_DETAIL = 'TEAM_CANT_PUBLISH_WITHOUT_IMAGE_DETAIL',
  CANT_UPDATE_DELETE_MINIGOLS_IN_A_USER_COLLECTION = 'CANT_UPDATE_DELETE_MINIGOLS_IN_A_USER_COLLECTION',
  CANT_UPDATE_DELETE_MINIGOLS_IN_A_USER_COLLECTION_DETAIL = 'CANT_UPDATE_DELETE_MINIGOLS_IN_A_USER_COLLECTION_DETAIL',
  TEAM_ALREADY_EXISTS_CANT_UPDATE = 'TEAM_ALREADY_EXISTS_CANT_UPDATE',
  MINIGOLS_MAX_AMOUNT = 'MINIGOLS_MAX_AMOUNT',
  MINIGOLS_SAME_SHIRT_NUMBER = 'MINIGOLS_SAME_SHIRT_NUMBER',
  CANT_DELETE_MINIGOLS_IN_A_PUBLISHED_TEAM = 'CANT_DELETE_MINIGOLS_IN_A_PUBLISHED_TEAM',
  MINIGOLS_FIELD_POSITION_REQUIRED = 'MINIGOLS_FIELD_POSITION_REQUIRED',
  MINIGOLS_SHIRTNUMBER_REQUIRED = 'MINIGOLS_SHIRTNUMBER_REQUIRED',
  MINIGOLS_MIN_AMOUNT = 'MINIGOLS_MIN_AMOUNT',
  MNFT_HAS_USER_ASSIGNED = 'MNFT_HAS_USER_ASSIGNED',
  MNFT_HAS_USER_ASSIGNED_TITLE = 'MNFT_HAS_USER_ASSIGNED_TITLE',
  FILE_NOT_EXISTS = 'FILE_NOT_EXISTS',
  DATE_AFTER_NOW = 'DATE_AFTER_NOW',
  NOTIFICATION_MESSAGE_REQUIRED = 'NOTIFICATION_MESSAGE_REQUIRED',
}

export interface ErrorResponse {
  type: ErrorEnum
  message?: string
}

import { useQuery } from 'react-query'

import { QUERY_KEYS, TransferListApi } from '@api'
import { Filters } from '@types'

const useTransferList = (filters: Filters) => {
  const { data, refetch } = useQuery([QUERY_KEYS.TRANSFERLIST, filters], () =>
    TransferListApi.getAll(filters),
  )
  return { transferList: data, refetch }
}

export { useTransferList }

import { useQuery } from 'react-query'

import { QUERY_KEYS, MinigolsApi } from '@api'
import { Filters } from '@types'

const useMinigols = (filters: Filters) => {
  const { data, refetch } = useQuery([QUERY_KEYS.MINIGOLS, filters], () =>
    MinigolsApi.getAll(filters),
  )
  return { minigols: data, refetch }
}

export { useMinigols }

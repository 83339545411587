import { useMutation } from 'react-query'

import { TeamsApi } from '@api'
import { ErrorResponse } from '@types'

interface UsePublishTeamProps {
  publish: boolean
  onError: (error: ErrorResponse) => void
}

const usePublishTeam = (props: UsePublishTeamProps) => {
  const { mutate: publishTeam, isSuccess: isPublishSuccess } = useMutation(
    !props.publish ? TeamsApi.publishTeam : TeamsApi.unpublishTeam,
    {
      onError: (error: ErrorResponse) => {
        console.error('Publish team error:', error)
        if (props?.onError) {
          props.onError(error)
        }
      },
      onSuccess: () => {
        console.log('SUCCESS')
      },
    },
  )

  return {
    publishTeam,
    isPublishSuccess,
  }
}

export { usePublishTeam }

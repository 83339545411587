export { ErrorEnum } from './errors'
export type { ErrorResponse } from './errors'

export type User = {
  id: number
  username: string
  createdAt: Date
}

export interface Account {
  id: number
  alias: string
  code: string
  createdAt: string
  currency: string
  bankName: string
}

export type BackofficeUser = {
  id: number
  username: string
  createdAt: Date
}

export type userKYCStates = {
  kycComplianceRenaper?: boolean
  kycComplianceAlive?: boolean
  kycPictureMatch?: boolean
  kycComplianceForm?: boolean
  kycComplianceBiometric?: boolean
  kycComplianceUif?: boolean
  kycComplianceNosis?: boolean
  kycComplianceWorldcheck?: boolean
  kycComplianceResidenceTax?: boolean
  kycRenaperDeceased?: boolean
  kycStepFinalized: UserKYCStatesEnum
}

export interface Profile {
  accounts: Account[]
  cuit?: string
  cuil?: string
  businessNameCuit?: string
  names: string
  surname: string
  country: string
  identityCard: string
  profession?: string
  alias?: string
  profilePicture?: string
  notificationEmail?: string
  dateOfBirth: string
  realAddress?: string
  telephone?: string
  isObligatedPersonUIF?: boolean
  isExposedPerson?: boolean
  gender?: string
  countryOfResidence?: Country
  taxCountry?: Country
  phoneNumber?: string
  fundsOrigins: string[]
  netWorth: string[]
  amountToInvest: string[]
  anualIncome: string[]
  nationality: string | null
  occupation: Occupation | null
  backofficeComments?: string
}

export interface Occupation {
  id: number
  createdAt: string
  name: string
}

export interface Country {
  id: number
  createdAt: Date
  name: string
  code: string
  availableToTrade: boolean
  currency: string
}

export enum UserKYCStatesEnum {
  NONE = 'NONE',
  FRONT = 'FRONT',
  BACK = 'BACK',
  LIVENESS = 'LIVENESS',
  PIN = 'PIN',
  FORM = 'FORM',
}

export interface AuthResponse {
  user: User
  token: string
  refreshToken: string
}

export interface Teams {
  name: string
  id: number
  image: string
  publish: boolean
  imageKey: string
  createdAt: string
}

export interface Filters {
  page: number
  limit: number
}

export interface MinigolsType {
  id: number
  name: string
  lastname: string
  lastName: string
  shirtNumber: number
  nickname: string
  fieldPosition: string
  image: string
  imageBaw: string
  imageKey: null
  team: Teams
  someUserHasInCollection: boolean
}

export interface MinigolType {
  id?: number
  name?: string
  lastname?: string
  shirtNumber?: number
  nickname?: string
  fieldPosition?: string
  teamId?: number
}

export interface AwardsType {
  id: number
  createdAt: string
  image: string
  imageKey: string
  name: string
  tokenId: string | null
  isNft: boolean
  owner: {
    id: number
    username: string
    firstName: string
    lastName: string
  }
  mNftToSend: {
    id: number
    createdAt: string
    walletInfo: string
    transferedDate: string | null
    transferNotes: string | null
  } | null
}

export interface AwardType {
  tokenId?: string
}

export interface AwardsForm {
  id: number
  userId?: number
  tokenId?: string
  name?: string
}

export interface TransferListTypeHandel {
  id: number
  owner: {
    username: string
    name: string
    lastname: string
  }
  walletInfo: string
  transferNote: string | null
  mnft: {
    id: number
    image: string
    token: string
  }
  createdAt: string
  transferdDate: null
}

export interface AwardsTransferValue {
  id: number
  mNftToSend: {
    id: number
    walletInfo: string
    createdAt: string
  }
  name?: string
  owner: {
    name: string
    username: string
    lastname: string
  }
  image: string
}

export enum Positions {
  GOALKEEPER = 'GOALKEEPER',
  DEFENDER = 'DEFENDER',
  MIDFIELDER = 'MIDFIELDER',
  FORWARD = 'FORWARD',
}

export interface PaginatedResult<T> {
  data: T[]
  total: number
}

export type UpdatePasswordParams = {
  token: string
  password: string
}

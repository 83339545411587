import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Landing, InputForm, InputFormPassword } from '@components'
import { ErrorEnum, ErrorResponse } from '@types'
import { logo } from '@images'
import { useUser } from '@contexts'
import { useApiErrorService } from '@hooks'

import { useLogin } from './hooks'
import { StyledButton, Messages } from './components'

const defaultValues = {
  password: '',
  username: '',
}

const Login = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message = useRef<any>(null)
  const { state: user } = useUser()
  const history = useHistory()
  const { t } = useTranslation()
  const { mapErrorToMessage } = useApiErrorService()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues })

  const onSubmit = data => {
    message.current.clear()
    login(data)
  }

  const { login, isLoading } = useLogin({
    onError: (error: ErrorResponse) => {
      if (error.type === ErrorEnum.LOGIN_ATTEMPTS_EXCEEDED) {
        history.push('/login/recover', { reason: t('login.account_locked') })
      } else {
        message.current.show({
          severity: 'error',
          detail: mapErrorToMessage(error),
          life: 10000,
        })
      }
    },
  })

  return user ? (
    <Redirect to="/equipos" />
  ) : (
    <Landing title={t('login.login_title')} headerImg={logo}>
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          control={control}
          error={errors.username}
          name="username"
          rules={{
            required: t('login.email_required'),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: t('login.email_invalid'),
            },
          }}
          placeholder={t('login.username_label')}
        />
        <InputFormPassword
          control={control}
          error={errors.password}
          name="password"
          rules={{ required: t('login.password_required') }}
          placeholder={t('login.password_label')}
        />
        <Messages ref={message} />
        <StyledButton loading={isLoading}>{t('login.login')}</StyledButton>
      </form>
    </Landing>
  )
}

export { Login }

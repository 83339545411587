/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Messages } from 'primereact/messages'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import { Image } from 'primereact/image'
import styled from 'styled-components'

import { AwardsTransferValue, AwardsType, ErrorResponse } from '@types'
import { useApiErrorService } from '@hooks'
import { TransferAward } from '@components'
import { StringUtils } from '@utils'

import { useAwards } from './hooks/useAwards'
import { useDeleteAwards } from './hooks/useDeleteAwards'
import { FormAward } from './components/formAward'

const ImgAward = styled.img`
  width: 60px;
  height: 60px;
  object-fit: scale-down;
`

const Awards = () => {
  const { t } = useTranslation()
  const message = useRef<any>(null)
  const toast = useRef<any>(null)
  const [awardsDialog, setAwardsDialog] = useState(false)
  const [infoWardsDialog, setInfoAwardsDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedAwards, setSelectedAwards] = useState<AwardsType>()
  const [mNftWithOwner, setMNftWithOwner] = useState(false)
  const [awardValue, setAwardValue] = useState<AwardsType>()
  const [awardTransfer, setAwardTrasnfer] = useState<AwardsTransferValue>()
  const [page, setPage] = useState(0)

  const { mapErrorToMessage } = useApiErrorService()

  const { awards, refetch } = useAwards({ page: page + 1, limit: rowsPerPage })
  const { deleteAwards, isDeleteSuccess } = useDeleteAwards({
    onError: (error: ErrorResponse) => {
      toast.current.show({
        severity: 'error',
        summary: mapErrorToMessage(error, '_TITLE'),
        detail: mapErrorToMessage(error),
        life: 5000,
      })
    },
  })

  const actionBodyTemplate = (rowData: AwardsType) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => editAwards(rowData)}
          disabled={
            !!rowData.mNftToSend?.transferedDate ||
            (!!rowData.owner.username && rowData.tokenId === null) ||
            (rowData.mNftToSend === null && rowData.tokenId !== null && !!rowData.owner.username)
          }
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => deleteSelectedAwards(rowData)}
          disabled={!!rowData.owner.username}
        />
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => infoAwards(rowData)}
        />
      </>
    )
  }

  const openNew = () => {
    setAwardValue(undefined)
    setAwardTrasnfer(undefined)
    setMNftWithOwner(false)
    setIsEdit(false)
    setAwardsDialog(true)
  }

  const headerControls = (
    <div className="table-header">
      <Button label={t('globals.new')} className="p-button-success" onClick={openNew} />
    </div>
  )

  const infoAwards = (awards: AwardsType) => {
    setSelectedAwards(awards)
    setInfoAwardsDialog(true)
  }

  const deleteSelectedAwards = (rowData: AwardsType) => {
    if (!rowData.owner.username) {
      confirmDialog({
        acceptLabel: t('globals.yes'),
        message: t('awards.confirm_delete_awards'),
        header: t('globals.confirmation_title'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => deleteAwards({ awardsId: rowData.id }),
      })
    }
  }

  const editAwards = (awards: AwardsType) => {
    const { id, mNftToSend, owner, image, name } = awards
    setMNftWithOwner(!!(owner.username && awards.tokenId))
    setIsEdit(true)
    setAwardsDialog(true)
    return !!owner.username && awards.tokenId
      ? setAwardTrasnfer({
          id,
          name,
          mNftToSend: {
            id: mNftToSend!.id,
            walletInfo: mNftToSend!.walletInfo,
            createdAt: mNftToSend!.createdAt,
          },
          owner: {
            lastname: owner.lastName,
            name: owner.firstName,
            username: owner.username,
          },
          image,
        })
      : setAwardValue(awards)
  }

  const imageBodyTemplate = (rowData: AwardsType) => {
    return (
      <ImgAward
        loading="lazy"
        src={StringUtils.formatImageUrl(rowData.image)}
        alt={`awards-${rowData.id}`}
      />
    )
  }

  const messageError = (event: ErrorResponse) => {
    message.current.show({
      severity: 'error',
      detail: mapErrorToMessage(event),
      life: 10000,
    })
  }

  useEffect(() => {
    if (isDeleteSuccess) {
      refetch()
      setAwardsDialog(false)
      toast.current.show({
        severity: 'success',
        summary: t('globals.deleted'),
        detail: t('awards.deleted'),
        life: 5000,
      })
    }
  }, [isDeleteSuccess, refetch, t])

  const handlePageChange = e => {
    setRowsPerPage(e.rows)
    setPage(e.page)
  }

  const textDateTransfer = () => {
    if (selectedAwards?.mNftToSend?.transferedDate) {
      const date = new Date(selectedAwards.mNftToSend.transferedDate)
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }
    return '-'
  }

  const nftBodyTemplate = (rowData: AwardsType) => {
    return rowData.tokenId ? t('globals.yes') : t('globals.no')
  }

  const transferredBodyTemplate = (rowData: AwardsType) => {
    return rowData.mNftToSend?.transferedDate ? t('globals.yes') : t('globals.no')
  }

  const userMailTemplate = (rowData: AwardsType) => {
    return rowData.owner?.username ?? '-'
  }

  const userLastNameTemplate = (rowData: AwardsType) => {
    return rowData.owner?.lastName ?? '-'
  }
  const awardNameTemplate = (rowData: AwardsType) => {
    return rowData.name ?? '-'
  }

  const userWalletTemplate = (rowData: AwardsType) => {
    if (rowData.mNftToSend?.walletInfo) {
      return `${rowData.mNftToSend?.walletInfo.substr(0, 10)}...`
    }
    return '-'
  }

  const userNameTemplate = (rowData: AwardsType) => {
    return rowData.owner?.firstName ?? '-'
  }

  return (
    <div className="card">
      <ConfirmDialog />
      <Toast ref={toast} />
      <Toolbar
        left={<h3>{t('awards.title')}</h3>}
        right={headerControls}
        style={{ marginBottom: '15px' }}
      />
      <DataTable
        value={awards?.data}
        className="p-datatable-striped"
        scrollable
        scrollHeight="flex"
        responsiveLayout="scroll"
        lazy
        loading={!awards?.data}
        paginator
        first={page * rowsPerPage}
        rows={rowsPerPage}
        totalRecords={awards?.total}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={t('awards.current_page_report')}
        onPage={e => handlePageChange(e)}
      >
        <Column field="id" header="#" style={{ minWidth: '3rem' }} />
        <Column
          field="image"
          header={t('awards.image')}
          style={{ minWidth: '7rem' }}
          body={imageBodyTemplate}
        />
        <Column
          field="owner.email"
          body={userMailTemplate}
          header={t('awards.userEmail')}
          style={{ minWidth: '19rem' }}
        />
        <Column
          field="owner.firstName"
          body={userNameTemplate}
          header={t('awards.name')}
          style={{ minWidth: '10rem' }}
        />
        <Column
          field="owner.lastName"
          body={userLastNameTemplate}
          header={t('awards.lastname')}
          style={{ minWidth: '10rem' }}
        />
        <Column field="nft" header="NFT" body={nftBodyTemplate} style={{ minWidth: '4rem' }} />
        <Column
          field="awardName"
          header={t('awards.awardName')}
          body={awardNameTemplate}
          style={{ minWidth: '10rem' }}
        />
        <Column
          field="wallet"
          body={userWalletTemplate}
          header={t('awards.wallet')}
          style={{ minWidth: '9rem' }}
        />
        <Column
          field="transferred"
          header={t('awards.transferred_table')}
          body={transferredBodyTemplate}
          style={{ minWidth: '8rem' }}
        />
        <Column
          header={t('globals.actions')}
          body={actionBodyTemplate}
          style={{ minWidth: '11rem' }}
        />
      </DataTable>
      <Dialog
        visible={infoWardsDialog}
        style={{ width: '400px' }}
        header={t('globals.info')}
        headerStyle={{ backgroundColor: '#27356C', color: '#fff' }}
        contentStyle={{
          padding: '0',
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
        modal
        className="p-fluid"
        focusOnShow={false}
        onHide={() => setInfoAwardsDialog(false)}
      >
        {selectedAwards !== undefined && (
          <div className="container-info-award">
            <div className="info-award-header">
              <h3>{t('awards.data_award')}</h3>
              <div className="info-award">
                <span className="text-award">Id: {selectedAwards.id}</span>
                <span className="text-award">
                  {t('awards.award')}:{' '}
                  {selectedAwards.tokenId ? t('awards.nft') : t('awards.image')}
                </span>
                <span className="text-award">
                  {`${t('awards.transferred')}: `}
                  {selectedAwards.mNftToSend?.transferedDate ? t('globals.yes') : t('globals.no')}
                </span>
              </div>
              <div className="container-img-award">
                <div className="img-award">
                  {selectedAwards.image && (
                    <Image
                      src={selectedAwards.image}
                      alt={`award-${selectedAwards.tokenId ? 'nft' : 'imag'}`}
                      preview
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="info-award-user">
              <h3>{t('awards.data_user')}</h3>
              <span>
                {t('awards.name')}: {selectedAwards?.owner?.firstName ?? '-'}
              </span>
              <span>
                {t('awards.lastname')}: {selectedAwards?.owner?.lastName ?? '-'}
              </span>
              <span>
                {t('awards.email')}: {selectedAwards?.owner?.username ?? '-'}
              </span>
              <span>
                {t('awards.awardName')}: {selectedAwards?.name?.length ? selectedAwards?.name : '-'}
              </span>
              <span>
                {t('awards.wallet')}: {selectedAwards?.mNftToSend?.walletInfo ?? '-'}
              </span>
              <span>
                {t('awards.tokenId')}: {selectedAwards?.tokenId ?? '-'}
              </span>
              <span>
                {t('awards.transference_date')}: {textDateTransfer()}
              </span>
              <span>
                {t('awards.note_transference')}: {selectedAwards?.mNftToSend?.transferNotes ?? '-'}
              </span>
            </div>
          </div>
        )}
      </Dialog>
      {mNftWithOwner ? (
        <TransferAward
          t={t}
          messageError={messageError}
          confirmDialog={confirmDialog}
          value={awardTransfer!}
          refetchProp={refetch}
          toast={toast}
          setAwardsDialogProp={setAwardsDialog}
          visibleDialog={awardsDialog}
        >
          <Messages ref={message} />
        </TransferAward>
      ) : (
        <FormAward
          t={t}
          isEditProp={isEdit}
          messageError={messageError}
          message={message}
          confirmDialog={confirmDialog}
          value={awardValue}
          refetchProp={refetch}
          toast={toast}
          setAwardsDialogProp={setAwardsDialog}
          visibleDialog={awardsDialog}
        >
          <Messages ref={message} />
        </FormAward>
      )}
    </div>
  )
}

export { Awards }

import { useEffect, useState } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { logo } from '@images'
import { Equipos, Minigols, Premios, Notificaciones, Reportes } from '@appIcons'

const HeaderImgMenu = styled.img`
  width: 250px;
`

const StyledBtnMenu = styled(Button)`
  color: #27356c !important;
  :focus {
    box-shadow: none;
  }
`

const MinigolsMenu = ({ handleOpen }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeSubMenu, setActiveSubMenu] = useState(false)

  const initialItems = [
    {
      label: t('menu.teams'),
      icon: <Equipos className="p-menuitem-icon" />,
      route: 'equipos',
    },
    {
      label: t('menu.minigols'),
      icon: <Minigols className="p-menuitem-icon" />,
      route: 'minigols',
    },
    {
      label: t('menu.notifications'),
      icon: <Notificaciones className="p-menuitem-icon" />,
      route: 'notificaciones',
    },
    {
      label: t('menu.awards'),
      icon: <i className="pi pi-chevron-right p-menuitem-icon" />,
      route: 'sub-menu-premios',
    },
  ]
  const [items, setItems] = useState(initialItems)

  useEffect(() => {
    if (activeSubMenu && items.length === 4) {
      const fixedItems = items.splice(0, 3)
      setItems([
        ...fixedItems,
        {
          label: t('menu.awards'),
          icon: <i className="pi pi-chevron-down p-menuitem-icon" />,
          route: 'sub-menu-premios',
        },
        {
          label: t('menu.list_awards'),
          icon: <Premios className="p-menuitem-icon" />,
          route: 'premios',
        },
        {
          label: t('menu.list_transfer'),
          icon: <Reportes className="p-menuitem-icon" />,
          route: 'transferencias-pendientes',
        },
      ])
    } else {
      setItems(initialItems)
    }
  }, [activeSubMenu])

  const menuClick = e => {
    if (e.value.route === 'sub-menu-premios') {
      setActiveSubMenu(activeSubMenu => !activeSubMenu)
    } else {
      setActiveIndex(e.index)
      history.push(e.value.route)
    }
  }

  return (
    <nav
      className={`tab-menu ${!isOpen ? 'hiddenTabMenu' : ''}`}
      style={isOpen ? { width: '298px' } : { width: '101px' }}
    >
      <header className="container-header-menu">
        <HeaderImgMenu src={logo} style={isOpen ? { display: 'block' } : { display: 'none' }} />
        <StyledBtnMenu
          icon={isOpen ? 'pi pi-align-right' : 'pi pi-bars'}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setIsOpen(isOpen => !isOpen)
            handleOpen(!isOpen)
          }}
        />
      </header>
      <TabMenu
        model={items}
        className={!isOpen ? 'hidden-text-menu' : ''}
        activeIndex={activeIndex}
        onTabChange={e => menuClick(e)}
      />
    </nav>
  )
}

export { MinigolsMenu }

import { AuthResponse } from '@types'

import { api } from './axios'

interface Payload {
  username: string
  password: string
}

interface ResendVerificationPayload {
  username: string
}

const login = async (payload: Payload) => {
  const body = { ...payload }
  const response = await api.post<AuthResponse>('/auth/admin-login', body)

  return response.data
}

const resendVerification = async ({ username }: ResendVerificationPayload) => {
  const response = await api.put(`/auth/resend-verification/${username}`)

  return response.data
}

export const AuthApi = {
  login,
  resendVerification,
}

import { useMutation } from 'react-query'

import { MinigolsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseUpdateMinigolsProps {
  onError?: (error: ErrorResponse) => void
}

const useUpdateMinigols = (props?: UseUpdateMinigolsProps) => {
  const {
    mutate: updateMinigol,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useMutation(MinigolsApi.updateMinigols, {
    onError: (error: ErrorResponse) => {
      console.error('update minigols error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    updateMinigol,
    isUpdateLoading,
    isUpdateSuccess,
  }
}

export { useUpdateMinigols }

import { TransferListTypeHandel, Filters } from '@types'

import { api } from './axios'

interface TransferAward {
  nftToSendId: number
  transferedDate: string
  notes: string
}

interface TransferListPayloadGet {
  data: TransferListTypeHandel[]
  total: number
}

const getAll = async ({ page, limit }: Filters) => {
  const params = {
    page,
    limit,
  }
  const response = await api.get<TransferListPayloadGet>('/mnft-to-send', { params })
  return response.data
}

const transferAwards = async (payload: TransferAward) => {
  const response = await api.put('/mnft-to-send/', payload)
  return response.data
}

export const TransferListApi = {
  getAll,
  transferAwards,
}

import { Teams, Filters } from '@types'

import { api } from './axios'

interface DeleteTeamPayload {
  teamId: number
}

interface TeamPayloadGet {
  data: Teams[]
  total: number
}

interface TeamPayload {
  teamId?: number
  name: string
  picture: File
}

const getAll = async ({ page, limit }: Filters) => {
  const params = {
    page,
    limit,
  }
  const response = await api.get<TeamPayloadGet>('/teams', { params })
  return response.data.data
}

const newTeam = async (payload: TeamPayload) => {
  const teamPayload = new FormData()
  if (payload.picture) teamPayload.append('picture', payload.picture)
  teamPayload.append('team', JSON.stringify({ name: payload.name }))

  const response = await api.post('/teams', teamPayload)
  return response.data
}

const updateTeam = async (payload: TeamPayload) => {
  const teamPayload = new FormData()
  if (payload.picture) teamPayload.append('picture', payload.picture)
  teamPayload.append('team', JSON.stringify({ name: payload.name }))

  const response = await api.put(`/teams/${payload.teamId}`, teamPayload)
  return response.data
}

const publishTeam = async (payload: DeleteTeamPayload) => {
  const response = await api.put(`/teams/publish/${payload.teamId}`)
  return response.data
}

const unpublishTeam = async (payload: DeleteTeamPayload) => {
  const response = await api.put(`/teams/unpublish/${payload.teamId}`)
  return response.data
}

const deleteTeam = async (payload: DeleteTeamPayload) => {
  const response = await api.delete(`/teams/${payload.teamId}`)
  return response.data
}

export const TeamsApi = {
  getAll,
  newTeam,
  updateTeam,
  deleteTeam,
  publishTeam,
  unpublishTeam,
}

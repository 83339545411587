import { css } from 'styled-components'

const fonts = css`
  //Nunito
  @font-face {
    font-family: 'Nunito-Regular';
    src: url('/fonts/Nunito/Nunito-Regular.ttf');
  } //400

  @font-face {
    font-family: 'XBall';
    src: url('/fonts/XBall/XBall.ttf');
  }

  @font-face {
    font-family: 'XBall Italic';
    src: url('/fonts/XBall/XBall Italic.ttf');
  }

  @font-face {
    font-family: 'Calibri';
    src: url('/fonts/calibri/Calibri.ttf');
  }
`

export { fonts }

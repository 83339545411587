import { useTranslation } from 'react-i18next'

import { Positions } from '@types'

const usePositions = () => {
  const { t } = useTranslation()

  const position = [
    { name: t('minigols.position_goalkeeper'), id: Positions.GOALKEEPER },
    { name: t('minigols.position_defender'), id: Positions.DEFENDER },
    { name: t('minigols.position_midfielder'), id: Positions.MIDFIELDER },
    { name: t('minigols.position_forward'), id: Positions.FORWARD },
  ]

  return { position }
}

export { usePositions }

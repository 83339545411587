import { useMutation } from 'react-query'

import { NotificationsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseUpdateNotificationProps {
  onError?: (error: ErrorResponse) => void
}

const useUpdateNotifications = (props?: UseUpdateNotificationProps) => {
  const {
    mutate: updateNotification,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useMutation(NotificationsApi.updateNotification, {
    onError: (error: ErrorResponse) => {
      console.error('Update notifications error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    updateNotification,
    isUpdateLoading,
    isUpdateSuccess,
  }
}

export { useUpdateNotifications }

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { BackofficeUsersApi, QUERY_KEYS } from '@api'
import { useUser } from '@contexts'

const onError = () => {
  console.log('No pudimos encontrar el usuario')
}

interface Props {
  onSuccess?: () => void
}

const useLoginResponseManager = (props?: Props) => {
  const { setState } = useUser()
  const history = useHistory()

  const { mutate, isLoading } = useMutation(
    [QUERY_KEYS.BACKOFFICE_USER],
    BackofficeUsersApi.getBackofficeUser,
    {
      onError,
      onSuccess: ({ id, createdAt, username }) => {
        setState({ id, createdAt, username })
        if (props?.onSuccess) props.onSuccess()
        if (history.location.pathname === '/login' || history.location.pathname === '/') {
          history.push('/equipos')
        }
      },
    },
  )

  return { isLoading, onSuccess: mutate }
}

export { useLoginResponseManager }

import { Controller, FieldError } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'

interface DropdownProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: any
  error?: FieldError
  name: string
  rules?: {
    required: string | boolean
    pattern?: { value: RegExp; message: string }
  }
  placeholder: string
  autoFocus?: boolean
  max?: number
  options: { name: string; value?: string; id?: string | number | boolean }[]
  optionValue?: string
  onFocus?: () => void
}

const DropDownForm = (props: DropdownProps) => {
  const getFormErrorMessage = () => {
    return props.error && <small className="p-error">{props.error.message}</small>
  }

  return (
    <div className="field">
      <span className="p-float-label">
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <Dropdown
              id={field.name}
              {...field}
              onChange={e => field.onChange(e.value)}
              optionLabel="name"
              optionValue={props.optionValue}
              options={props.options}
              autoFocus={props.autoFocus ?? true}
              onShow={props.onFocus}
              onHide={props.onFocus}
              className={classNames({ 'p-invalid': fieldState.invalid })}
              maxLength={props?.max}
            />
          )}
        />
        <label
          htmlFor={props.name}
          className={classNames({
            'p-error': !!props.error,
          })}
        >
          {props.placeholder}
        </label>
      </span>
      {getFormErrorMessage()}
    </div>
  )
}

export { DropDownForm }

import { useMutation } from 'react-query'

import { NotificationsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseNewNotificationProps {
  onError?: (error: ErrorResponse) => void
}

const useNewNotifications = (props?: UseNewNotificationProps) => {
  const {
    mutate: createNewNotification,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
  } = useMutation(NotificationsApi.newNotification, {
    onError: (error: ErrorResponse) => {
      console.error('Create notifications error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    createNewNotification,
    isCreateLoading,
    isCreateSuccess,
  }
}

export { useNewNotifications }

import { useTranslation } from 'react-i18next'

import {
  StyledContentContainer,
  StyledTitle,
  StyledSubtitle,
} from '../../UpdatePassword/components'

export const AccountFailure = () => {
  const { t } = useTranslation()

  return (
    <StyledContentContainer>
      <StyledTitle className="steps-title">{t('verifyUser.failure.title')}</StyledTitle>
      <StyledSubtitle className="confirm-pass-input">{t('verifyUser.failure.body')}</StyledSubtitle>
    </StyledContentContainer>
  )
}

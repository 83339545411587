import { useMutation } from 'react-query'

import { TeamsApi } from '@api'
import { ErrorResponse } from '@types'

const useDeleteTeam = () => {
  const { mutate: deleteTeam, isSuccess: isDeleteSuccess } = useMutation(TeamsApi.deleteTeam, {
    onError: (error: ErrorResponse) => {
      console.error('Delete team error:', error)
    },
    onSuccess: () => {
      console.log('SUCCESS')
    },
  })

  return {
    deleteTeam,
    isDeleteSuccess,
  }
}

export { useDeleteTeam }

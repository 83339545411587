import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

import { QUERY_KEYS } from '@api'

import { AuthApi } from './api'

const useAuth = (onError: (error: AxiosError) => void) => {
  const { mutate: updatePassword, isLoading } = useMutation(
    [QUERY_KEYS.UPDATE_USER_PASSWORD],
    AuthApi.updatePassword,
  )
  const { mutate: verifyUser, isLoading: isVerifying } = useMutation(
    [QUERY_KEYS.VERIFY_USER],
    AuthApi.verifyUser,
    {
      onError,
    },
  )

  return { updatePassword, verifyUser, isLoading, isVerifying }
}

export { useAuth }

import { useQuery } from 'react-query'

import { QUERY_KEYS, TeamsApi } from '@api'
import { Filters } from '@types'

const useTeams = (filters: Filters) => {
  const { data, refetch } = useQuery([QUERY_KEYS.TEAMS, filters], () => TeamsApi.getAll(filters))
  return { teams: data, refetch }
}

export { useTeams }

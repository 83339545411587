import { InputForm, InputFormPassword, HeaderImg } from './components'

interface LandingProps {
  title: string
  isLoading?: boolean
  children: React.ReactNode
  headerImg?: string
}

const Landing = (props: Partial<LandingProps>) => {
  return (
    <div className="container-card">
      <div className="card-login">
        <header className="landing-header">
          {props.headerImg && <HeaderImg src={props.headerImg} />}
        </header>
        <main>
          <h5 className="text-landing">{props.title}</h5>
          {props.children}
        </main>
      </div>
    </div>
  )
}

export { Landing, InputForm, InputFormPassword }

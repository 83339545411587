/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect } from 'react'
import { Calendar } from 'primereact/calendar'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { useForm, Controller } from 'react-hook-form'
import { Image } from 'primereact/image'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'

import { ErrorResponse, AwardsTransferValue } from '@types'

import { useTransferAwards } from './hooks/useTransferAwards'

interface AwardsTransfer {
  transferred: boolean
  date: Date
  time: Date
  message: string
}

interface FormAwardTransferProps {
  children: React.ReactNode
  t: (key: string) => string
  messageError: (error: ErrorResponse) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmDialog: (options: any) => void
  value: AwardsTransferValue
  refetchProp: () => void
  toast: React.MutableRefObject<Toast>
  setAwardsDialogProp: (value: boolean) => void
  visibleDialog: boolean
}

const TransferAward = ({
  children,
  t,
  visibleDialog,
  messageError,
  confirmDialog,
  value,
  refetchProp,
  toast,
  setAwardsDialogProp,
}: FormAwardTransferProps) => {
  const defaultValues: AwardsTransfer = {
    transferred: false,
    date: new Date(),
    time: new Date(),
    message: '',
  }

  const { control, handleSubmit, getValues, setValue, reset } = useForm({ defaultValues })

  const onSubmit = (data: AwardsTransfer) => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: t('awards.confirm_transfer_awards'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => confirmSubmit(data),
    })
  }

  const confirmSubmit = (data: AwardsTransfer) => {
    const arrayDate = data.date.toISOString().split('T')
    const awardsData = {
      mNftToSendId: value.mNftToSend.id,
      transferedDate: `${arrayDate[0]} ${data.time.toLocaleTimeString()}`,
      notes: data.message,
    }
    transferAward(awardsData)
  }

  const { transferAward, isTransferLoading, isTransferSuccess } = useTransferAwards({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  useEffect(() => {
    if (isTransferSuccess) {
      reset()
      refetchProp()
      setAwardsDialogProp(false)
      toast.current.show({
        severity: 'success',
        summary: t('globals.registered'),
        detail: t('awards.transfer_success'),
        life: 5000,
      })
    }
  }, [isTransferSuccess, refetchProp, setAwardsDialogProp, t, toast])

  const closeDialog = () => {
    const { message, date, time } = getValues()
    if (
      message ||
      date.getDate() !== defaultValues.date.getDate() ||
      time.getHours() !== defaultValues.time.getHours() ||
      time.getMinutes() !== defaultValues.time.getMinutes()
    ) {
      confirmDialog({
        acceptLabel: t('globals.yes'),
        message: t('globals.confirm_close_form'),
        header: t('globals.confirmation_title'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          setAwardsDialogProp(false)
          reset()
        },
      })
    } else {
      setAwardsDialogProp(false)
    }
  }

  return (
    <Dialog
      visible={visibleDialog}
      draggable={false}
      focusOnShow={false}
      style={{ width: '700px' }}
      header={t('awards.transfer_award')}
      modal
      className="p-fluid"
      onHide={() => closeDialog()}
    >
      <form className="dialog-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="container-info-user-awards">
          <div className="info-user-awards">
            <span>
              {`${t('awards.name')}: `} {value?.owner?.name ?? '-'}
            </span>
            <span>
              {`${t('awards.email')}: `} {value?.owner.username ?? '-'}
            </span>
            <span>
              {`${t('awards.wallet')}: `} {value?.mNftToSend.walletInfo ?? '-'}
            </span>
          </div>
          <div className="info-user-awards">
            <span>
              {`${t('awards.lastname')}: `} {value?.owner?.lastname ?? '-'}
            </span>
          </div>
          <Image className="img-awards" src={value?.image} alt={`award-${value?.id}`} preview />
        </div>
        <div className="container-date">
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <Calendar
                    id={field.name}
                    value={field.value}
                    onChange={e => {
                      setValue('time', new Date(e.value!.toString()))
                      field.onChange(e.value)
                    }}
                    dateFormat="dd/mm/yy"
                    mask="99/99/9999"
                    maxDate={new Date()}
                    minDate={new Date(value?.mNftToSend.createdAt)}
                    showIcon
                    required
                    locale="es"
                  />
                )}
              />
              <label htmlFor="date">{t('awards.date')}</label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="time"
                control={control}
                render={({ field }) => {
                  return (
                    <Calendar
                      timeOnly
                      hourFormat="24"
                      id={field.name}
                      value={field.value}
                      onChange={e => {
                        const { date } = getValues()
                        if (
                          e.value &&
                          date.toLocaleDateString() ===
                            new Date(e.value!.toString()).toLocaleDateString()
                        ) {
                          field.onChange(e.value)
                        }
                      }}
                      maxDate={new Date()}
                      minDate={new Date(value?.mNftToSend.createdAt)}
                      required
                    />
                  )
                }}
              />
              <label htmlFor="time">{t('awards.hour')}</label>
            </span>
          </div>
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  rows={5}
                  cols={30}
                  autoResize
                  id={field.name}
                  value={field.value}
                  onChange={e => field.onChange(e.target.value)}
                  required
                />
              )}
            />
            <label htmlFor="message">{t('awards.message')}</label>
          </span>
        </div>
        {children}
        <div className="dialog-footer">
          <Button
            label={t('globals.cancel')}
            icon="pi pi-times"
            type="button"
            onClick={() => closeDialog()}
            className="p-button-text"
          />
          <Button
            label={t('globals.save')}
            loading={isTransferLoading}
            icon="pi pi-check"
            className="p-button-text"
          />
        </div>
      </form>
    </Dialog>
  )
}

export { TransferAward }

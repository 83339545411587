import { useMutation } from 'react-query'

import { TeamsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseNewTeamProps {
  onError?: (error: ErrorResponse) => void
}

const useNewTeam = (props?: UseNewTeamProps) => {
  const {
    mutate: createNewTeam,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
  } = useMutation(TeamsApi.newTeam, {
    onError: (error: ErrorResponse) => {
      console.error('Create team error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    createNewTeam,
    isCreateLoading,
    isCreateSuccess,
  }
}

export { useNewTeam }

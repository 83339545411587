import { useMutation } from 'react-query'

import { AwardsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseDeleteAwardsProps {
  onError: (error: ErrorResponse) => void
}

const useDeleteAwards = (props: UseDeleteAwardsProps) => {
  const { mutate: deleteAwards, isSuccess: isDeleteSuccess } = useMutation(AwardsApi.deleteAwards, {
    onError: (error: ErrorResponse) => {
      console.error('Delete award error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
    onSuccess: () => {
      console.log('SUCCESS')
    },
  })

  return {
    deleteAwards,
    isDeleteSuccess,
  }
}

export { useDeleteAwards }

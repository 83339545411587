import { useMutation } from 'react-query'

import { MinigolsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseDeleteMinigolsProps {
  onError?: (error: ErrorResponse) => void
}

const useDeleteMinigols = (props?: UseDeleteMinigolsProps) => {
  const { mutate: deleteMinigols, isSuccess: isDeleteSuccess } = useMutation(
    MinigolsApi.deleteMinigols,
    {
      onError: (error: ErrorResponse) => {
        console.error('Delete minigols error:', error)
        if (props?.onError) {
          props.onError(error)
        }
      },
      onSuccess: () => {
        console.log('SUCCESS')
      },
    },
  )

  return {
    deleteMinigols,
    isDeleteSuccess,
  }
}

export { useDeleteMinigols }

import { MinigolsType, MinigolType, Filters } from '@types'

import { api } from './axios'

interface MinigolsPayload {
  minigolsId?: number
  minigols: MinigolType
  picture: File
  pictureBaw: File
}

interface MinigolsPayloadGet {
  data: MinigolsType[]
  total: number
}

interface DeleteMinigolsPayload {
  minigolsId: number
}

const getAll = async ({ page, limit }: Filters) => {
  const params = {
    page,
    limit,
  }
  const response = await api.get<MinigolsPayloadGet>('/minigols', { params })
  return response.data
}

const newMinigols = async (payload: MinigolsPayload) => {
  const minigolsPayload = new FormData()
  const { id, ...minigolsUpdateAttributes } = payload.minigols
  if (payload.picture) minigolsPayload.append('picture', payload.picture)
  if (payload.pictureBaw) minigolsPayload.append('pictureBaw', payload.pictureBaw)
  minigolsPayload.append('minigols', JSON.stringify(minigolsUpdateAttributes))

  const response = await api.post('/minigols', minigolsPayload)
  return response.data
}

const updateMinigols = async (payload: MinigolsPayload) => {
  const minigolsPayload = new FormData()
  if (payload.picture) minigolsPayload.append('picture', payload.picture)
  if (payload.pictureBaw) minigolsPayload.append('pictureBaw', payload.pictureBaw)

  minigolsPayload.append('minigols', JSON.stringify(payload.minigols))

  const response = await api.put(`/minigols/${payload.minigolsId}`, minigolsPayload)
  return response.data
}

const deleteMinigols = async (payload: DeleteMinigolsPayload) => {
  const response = await api.delete(`/minigols/${payload.minigolsId}`)
  return response.data
}

export const MinigolsApi = {
  getAll,
  newMinigols,
  deleteMinigols,
  updateMinigols,
}

import { AxiosError } from 'axios'
import { Toast } from 'primereact/toast'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  StyledContentContainer,
  StyledTitle,
  StyledSubtitle,
  StyledFormLabel,
  StyledInput,
  StyledConfirmButton,
} from '.'
import { useAuth } from '../../hooks'

export const PasswordStep = ({ current, next, setUpdateState }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')
  const [passwordValue, setPasswordValue] = useState({
    firstPassword: '',
    confirmPassword: '',
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toast = useRef<any>(null)
  const onUpdateSuccess = () => {
    setUpdateState(true)
    next()
  }
  const onUpdateError = () => {
    setUpdateState(false)
    toast.current.show({
      severity: 'error',
      details: t('users.notifications.password_error'),
    })
    next()
  }

  const { updatePassword, isLoading: isUpdating } = useAuth((error: AxiosError) => {
    toast.current.show({
      severity: 'error',
      detail: error.message,
      life: 5000,
    })
  })
  const handleConfirm = () => {
    const { confirmPassword: password } = passwordValue
    if (token) {
      updatePassword(
        { token, password },
        {
          onSuccess: onUpdateSuccess,
          onError: onUpdateError,
        },
      )
    }
  }
  return (
    <StyledContentContainer>
      <Toast ref={toast} />
      <StyledTitle className="steps-title">Cambiar contraseña</StyledTitle>
      <StyledSubtitle className="confirm-pass-input">Ingresá tu nueva contraseña</StyledSubtitle>
      <StyledFormLabel htmlFor="contraseña">Nueva contraseña</StyledFormLabel>
      <StyledInput
        className="web-input"
        feedback={false}
        toggleMask
        value={passwordValue.firstPassword}
        id="contraseña"
        onChange={e => setPasswordValue({ ...passwordValue, firstPassword: e.currentTarget.value })}
      />
      <StyledFormLabel style={{ marginTop: '10%' }} htmlFor="confirmar">
        Confirmar contraseña
      </StyledFormLabel>
      <StyledInput
        className="web-input"
        toggleMask
        feedback={false}
        value={passwordValue.confirmPassword}
        id="confirmar"
        onChange={e =>
          setPasswordValue({ ...passwordValue, confirmPassword: e.currentTarget.value })
        }
      />
      {current !== 2 && (
        <>
          {isUpdating && (
            <ProgressSpinner
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '4%',
              }}
            />
          )}
          <StyledConfirmButton
            className={
              !passwordValue.firstPassword ||
              !passwordValue.confirmPassword ||
              passwordValue.firstPassword !== passwordValue.confirmPassword
                ? 'disabled'
                : ''
            }
            onClick={handleConfirm}
            disabled={
              passwordValue.firstPassword !== passwordValue.confirmPassword ||
              !passwordValue.firstPassword ||
              !passwordValue.confirmPassword ||
              passwordValue.confirmPassword.length < 8 ||
              passwordValue.firstPassword.length < 8
            }
          >
            Confirmar
          </StyledConfirmButton>
        </>
      )}
    </StyledContentContainer>
  )
}

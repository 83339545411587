import { useMutation } from 'react-query'

import { TeamsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseUpdateTeamProps {
  onError?: (error: ErrorResponse) => void
}

const useUpdateTeam = (props?: UseUpdateTeamProps) => {
  const {
    mutate: updateTeam,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useMutation(TeamsApi.updateTeam, {
    onError: (error: ErrorResponse) => {
      console.error('Update team error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    updateTeam,
    isUpdateLoading,
    isUpdateSuccess,
  }
}

export { useUpdateTeam }

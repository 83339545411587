import { useTranslation } from 'react-i18next'

import { ErrorEnum, ErrorResponse } from '@types'

const useApiErrorService = () => {
  const { t } = useTranslation()

  const mapErrorToMessage = (error: ErrorResponse, detail?: string): string => {
    switch (detail ? error.type + detail : error.type) {
      case ErrorEnum.INCORRECT_USER_PASSWORD:
        return t('login.api.incorrect_user_password')
      case ErrorEnum.USER_NOT_VERIFIED:
        return t('login.api.user_not_verified')
      // TEAMS
      case ErrorEnum.TEAM_ALREADY_EXISTS_CANT_UPDATE:
      case ErrorEnum.TEAM_ALREADY_EXISTS:
        return t('teams.api.team_already_exists')
      case ErrorEnum.TEAM_MAX_AMOUNT:
        return t('teams.api.team_max_amount')
      case ErrorEnum.TEAM_DOESNT_EXISTS:
        return t('teams.api.team_doesnt_exists')
      case ErrorEnum.TEAM_CANT_PUBLISH:
        return t('teams.api.team_cant_publish')
      case ErrorEnum.TEAM_CANT_PUBLISH_DETAIL:
        return t('teams.api.team_cant_publish_detail')
      case ErrorEnum.TEAM_ALREADY_PUBLISHED:
        return t('teams.api.team_already_published')
      case ErrorEnum.TEAM_CANT_PUBLISH_WITHOUT_IMAGE:
        return t('teams.api.team_cant_publish_without_image')
      case ErrorEnum.TEAM_CANT_PUBLISH_WITHOUT_IMAGE_DETAIL:
        return t('teams.api.team_cant_publish_without_image_detail')
      case ErrorEnum.CANT_UPDATE_DELETE_MINIGOLS_IN_A_USER_COLLECTION:
        return t('teams.api.cant_update_delete_minigols_in_a_user_collection')
      case ErrorEnum.CANT_UPDATE_DELETE_MINIGOLS_IN_A_USER_COLLECTION_DETAIL:
        return t('teams.api.cant_update_delete_minigols_in_a_user_collection_detail')
      // MINIGOLS
      case ErrorEnum.MINIGOLS_MAX_AMOUNT:
        return t('minigols.api.minigols_max_amount')
      case ErrorEnum.MINIGOLS_SAME_SHIRT_NUMBER:
        return t('minigols.api.minigols_same_shirt_number')
      case ErrorEnum.CANT_DELETE_MINIGOLS_IN_A_PUBLISHED_TEAM:
        return t('minigols.api.cant_delete_minigols_in_a_published_team')
      case ErrorEnum.MINIGOLS_MIN_AMOUNT:
        return t('minigols.api.minigols_min_amount')
      case ErrorEnum.MINIGOLS_FIELD_POSITION_REQUIRED:
        return t('minigols.api.error_empty_field')
      case ErrorEnum.MINIGOLS_SHIRTNUMBER_REQUIRED:
        return t('minigols.api.minigols_shirtnumber_required')
      // AWARDS
      case ErrorEnum.MNFT_HAS_USER_ASSIGNED:
        return t('awards.api.mnft_has_user_assigned')
      case ErrorEnum.MNFT_HAS_USER_ASSIGNED_TITLE:
        return t('awards.api.mnft_has_user_assigned_title')
      case ErrorEnum.FILE_NOT_EXISTS:
        return t('awards.api.file_not_exists')
      // NOTIFICATIONS
      case ErrorEnum.DATE_AFTER_NOW:
        return t('notifications.api.date_after_now')
      case ErrorEnum.NOTIFICATION_MESSAGE_REQUIRED:
        return t('notifications.api.notification_message_required')
      default:
        return t('globals.generic_error')
    }
  }

  return { mapErrorToMessage }
}

export { useApiErrorService }

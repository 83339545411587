import { useMutation } from 'react-query'

import { AwardsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseTransferAwardsProps {
  onError?: (error: ErrorResponse) => void
}

const useTransferAwards = (props?: UseTransferAwardsProps) => {
  const {
    mutate: transferAward,
    isLoading: isTransferLoading,
    isSuccess: isTransferSuccess,
  } = useMutation(AwardsApi.transferAwards, {
    onError: (error: ErrorResponse) => {
      console.error('Transfer award error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    transferAward,
    isTransferLoading,
    isTransferSuccess,
  }
}

export { useTransferAwards }

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Image } from 'primereact/image'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { Checkbox } from 'primereact/checkbox'

import { AwardsForm, AwardsType, ErrorResponse } from '@types'
import { DropDownForm, InputForm } from '@components'
import { StringUtils } from '@utils'

import { useMNfts } from '../../hooks/useMnfts'
import { useUpdateAwards } from '../../hooks/useUpdateAwards'
import { useNewAwards } from '../../hooks/useNewAwards'

interface FormAwardProps {
  children: React.ReactNode
  t: (key: string) => string
  isEditProp: boolean
  message: any
  messageError: (error: ErrorResponse) => void
  confirmDialog: (options: any) => void
  value: AwardsType | undefined
  refetchProp: () => void
  toast: React.MutableRefObject<Toast>
  setAwardsDialogProp: (value: boolean) => void
  visibleDialog: boolean
}

const FormAward = ({
  children,
  t,
  isEditProp,
  messageError,
  message,
  confirmDialog,
  value,
  refetchProp,
  toast,
  setAwardsDialogProp,
  visibleDialog,
}: FormAwardProps) => {
  const fileUploadRef = useRef<any>(null)
  const [imgAwards, setImgAwards] = useState<File | undefined>()
  const [imgTeamOld, setImgTeamOld] = useState<string | undefined>()
  const defaultValues = {
    id: 0,
    userId: 0,
    tokenId: '',
    name: '',
    isNft: false,
  }
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues })
  const { mNFTs } = useMNfts()
  const [autogenerateTokenId, setAutogenerateTokenId] = useState(false)
  useEffect(() => {
    setAutogenerateTokenId(false)
    if (value) {
      setValue('id', value.id)
      setValue('tokenId', value.tokenId ?? '')
      setValue('isNft', !!value.tokenId)
      setValue('name', value.name)
      setImgTeamOld(StringUtils.formatImageUrl(value.image))
    } else {
      reset()
      if (fileUploadRef.current) fileUploadRef.current.clear()
      setImgTeamOld(undefined)
      setImgAwards(undefined)
    }
  }, [setValue, value, reset])

  const watchIsNft = watch('isNft', false)

  const onSubmit = (data: AwardsForm) => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: isEditProp ? t('awards.confirm_update_awards') : t('awards.confirm_create_awards'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => confirmSubmit(data),
    })
  }

  const confirmSubmit = (data: AwardsForm) => {
    const awardsData = {
      awardsId: data.id,
      awards: {
        tokenId: watchIsNft ? data.tokenId : undefined,
      },
      picture: imgAwards as File,
      name: data.name,
    }
    message.current.clear()
    reset()
    return isEditProp ? updateAward(awardsData) : createNewAward(awardsData)
  }

  const { createNewAward, isCreateLoading, isCreateSuccess } = useNewAwards({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  const { updateAward, isUpdateLoading, isUpdateSuccess } = useUpdateAwards({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  useEffect(() => {
    if (isUpdateSuccess) {
      refetchProp()
      setAwardsDialogProp(false)
      toast.current.show({
        severity: 'success',
        summary: t('globals.updated'),
        detail: t('awards.updated'),
        life: 5000,
      })
      setImgAwards(undefined)
    }
  }, [isUpdateSuccess, refetchProp, setAwardsDialogProp, t, toast])

  useEffect(() => {
    if (isCreateSuccess) {
      refetchProp()
      setAwardsDialogProp(false)
      toast.current.show({
        severity: 'success',
        summary: t('globals.created'),
        detail: t('awards.created'),
        life: 5000,
      })
      setImgAwards(undefined)
    }
  }, [isCreateSuccess, refetchProp, setAwardsDialogProp, t, toast])

  useEffect(() => {
    if (autogenerateTokenId && !value?.tokenId?.length) {
      setValue('tokenId', uuidv4())
    }
  }, [autogenerateTokenId])
  const closeDialog = () => {
    if (imgAwards) {
      confirmDialog({
        acceptLabel: t('globals.yes'),
        message: t('globals.confirm_close_form'),
        header: t('globals.confirmation_title'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => setAwardsDialogProp(false),
      })
    } else {
      setAwardsDialogProp(false)
    }
  }

  return (
    <Dialog
      visible={visibleDialog}
      draggable={false}
      focusOnShow={false}
      style={{ width: '700px' }}
      header={isEditProp ? t('awards.edit_award') : t('awards.new_award')}
      modal
      className="p-fluid"
      onHide={() => closeDialog()}
    >
      <form className="dialog-form" onSubmit={handleSubmit(onSubmit)}>
        <DropDownForm
          control={control}
          name="isNft"
          rules={{
            required: false,
          }}
          optionValue="id"
          options={mNFTs}
          autoFocus
          placeholder={t('awards.type_award')}
        />

        {watchIsNft && (
          <>
            <InputForm
              control={control}
              name="tokenId"
              placeholder={t('awards.tokenId')}
              error={errors.tokenId}
              rules={{ required: '' }}
            />
            <div className="field-checkbox" style={{ marginTop: '-1em', marginBottom: '1em' }}>
              <Checkbox
                name="autogenerateTokenId"
                inputId="autogenerateTokenId"
                checked={autogenerateTokenId}
                onChange={event => setAutogenerateTokenId(event.checked)}
                title={t('awards.type_award')}
              />
              <label htmlFor="autogenerateTokenId">{t('awards.autogenerateTokenId')}</label>
            </div>
          </>
        )}

        <InputForm
          control={control}
          name="name"
          placeholder={t('awards.name')}
          error={errors.name}
          rules={{ required: '' }}
        />
        <div className="container-file-img">
          <FileUpload
            ref={fileUploadRef}
            name="image"
            accept="image/png, image/jpeg"
            auto
            emptyTemplate={<p>{t('globals.image_drop')}</p>}
            customUpload
            chooseOptions={{ label: t('globals.image_choose'), className: 'p-button-without-icon' }}
            uploadHandler={event => setImgAwards(event.files[0])}
            onRemove={() => setImgAwards(undefined)}
          />
          {isEditProp && <Image src={imgTeamOld} alt={`award-${value?.id}`} preview />}
        </div>
        {isEditProp && (
          <div className="info-user-edit-awards">
            <div className="info-user-awards">
              <span>
                {`${t('awards.name')}: `} {value?.owner?.firstName ?? '-'}
              </span>
              <span>
                {`${t('awards.email')}: `} {value?.owner?.username ?? '-'}
              </span>
              <span>
                {`${t('awards.wallet')}: `} {value?.mNftToSend?.walletInfo ?? '-'}
              </span>
              <span>{`${t('awards.transferred')}: `}No</span>
              <span>
                {`${t('awards.transference_date')}: `} {value?.mNftToSend?.transferedDate ?? '-'}
              </span>
              <span>
                {`${t('awards.note_transference')}: `} {value?.mNftToSend?.transferNotes ?? '-'}
              </span>
            </div>
            <div className="info-user-awards">
              <span>
                {`${t('awards.lastname')}: `}
                {value?.owner?.lastName ?? '-'}
              </span>
            </div>
          </div>
        )}
        {children}
        <div className="dialog-footer">
          <Button
            label={t('globals.cancel')}
            icon="pi pi-times"
            type="button"
            onClick={() => closeDialog()}
            className="p-button-text"
          />
          <Button
            label={t('globals.save')}
            loading={isCreateLoading || isUpdateLoading}
            icon="pi pi-check"
            className="p-button-text"
          />
        </div>
      </form>
    </Dialog>
  )
}

export { FormAward }

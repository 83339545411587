import { useQuery } from 'react-query'

import { QUERY_KEYS, AwardsApi } from '@api'
import { Filters } from '@types'

const useAwards = (filters: Filters) => {
  const { data, refetch } = useQuery([QUERY_KEYS.AWARDS, filters], () => AwardsApi.getAll(filters))
  return { awards: data, refetch }
}

export { useAwards }

import { useMutation } from 'react-query'

import { NotificationsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseNotificationProps {
  onError?: (error: ErrorResponse) => void
}

const useDeleteNotifications = (props?: UseNotificationProps) => {
  const { mutate: deleteNotification, isSuccess: isDeleteSuccess } = useMutation(
    NotificationsApi.deleteNotification,
    {
      onError: (error: ErrorResponse) => {
        console.error('Delete notifications error:', error)
        if (props?.onError) props?.onError(error)
      },
    },
  )

  return {
    deleteNotification,
    isDeleteSuccess,
  }
}

export { useDeleteNotifications }

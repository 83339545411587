import { StyledContentContainer, StyledTitle, StyledSubtitle } from '.'

export const SuccessStep = () => {
  return (
    <StyledContentContainer>
      <StyledTitle className="steps-title">¡Tu contraseña ha sido creada con éxito!</StyledTitle>
      <StyledSubtitle className="confirm-pass-input">
        Abrí la aplicación desde tu celular para continuar disfrutando de MinigolsX
      </StyledSubtitle>
    </StyledContentContainer>
  )
}

import { useMutation } from 'react-query'

import { MinigolsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseNewMinigolsProps {
  onError?: (error: ErrorResponse) => void
}

const useNewMinigols = (props?: UseNewMinigolsProps) => {
  const {
    mutate: createNewMinigol,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
  } = useMutation(MinigolsApi.newMinigols, {
    onError: (error: ErrorResponse) => {
      console.error('Create minigols error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    createNewMinigol,
    isCreateLoading,
    isCreateSuccess,
  }
}

export { useNewMinigols }

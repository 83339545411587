export const QUERY_KEYS = {
  BACKOFFICE_USER: 'backofficeUser',
  TEAMS: 'teams',
  MINIGOLS: 'minigols',
  NOTIFICATIONS: 'notifications',
  AWARDS: 'awards',
  TRANSFERLIST: 'transferList',
  UPDATE_USER_PASSWORD: 'userPasswordUpdate',
  VERIFY_USER: 'userVerify',
}

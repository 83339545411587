import styled from 'styled-components'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'

const StyledButton = styled(Button)`
  width: 100% !important;
  background: ${props => props.theme.primaryColor};
  border: 1px solid ${props => props.theme.primaryColor} !important;
  display: flex;
  justify-content: center;
  gap: 10px;
  :hover {
    background: ${props => props.theme.blue} !important;
  }
`

export { StyledButton, Messages }

import { AxiosError } from 'axios'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Toast } from 'primereact/toast'

import { StyledContainer } from '../UpdatePassword/components/index'
import background from '../../../assets/images/web-background.png'
import { useAuth } from '../hooks'
import { AccountSuccess } from './components/AccountSuccess'
import { AccountFailure } from './components/AccountFailure'
import { PublicLanding } from '../../../components/PublicLanding/components'

export const VerifyUser = () => {
  const { search } = useLocation()
  const [userVerified, setUserVerified] = useState<boolean | null>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toast = useRef<any>(null)
  const { verifyUser, isVerifying } = useAuth((error: AxiosError) => {
    toast.current.show({
      severity: 'error',
      detail: error.message,
      life: 5000,
    })
  })
  const onSuccess = () => {
    setUserVerified(true)
  }
  const onError = () => {
    setUserVerified(false)
  }
  const token = new URLSearchParams(search).get('token')
  useEffect(() => {
    if (token) {
      verifyUser(token, {
        onSuccess,
        onError,
      })
    }
  }, [token, verifyUser])
  return (
    <StyledContainer
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <PublicLanding>
        <Toast ref={toast} />
        {isVerifying && <ProgressSpinner />}
        <div style={{ height: '50vh' }}>
          {userVerified === null && <></>}
          {userVerified === true && (
            <div className="steps-content">
              <AccountSuccess />
            </div>
          )}
          {userVerified === false && (
            <div className="steps-content">
              <AccountFailure />
            </div>
          )}
        </div>
      </PublicLanding>
    </StyledContainer>
  )
}

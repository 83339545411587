import { useMutation } from 'react-query'

import { useLoginResponseManager } from '@hooks'
import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { ErrorResponse } from '@types'

interface UseLoginProps {
  onError?: (error: ErrorResponse) => void
}

const useLogin = (props?: UseLoginProps) => {
  const { onSuccess } = useLoginResponseManager()

  const { mutate, isLoading } = useMutation(AuthApi.login, {
    onSuccess: ({ token, refreshToken, user }) => {
      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)

      onSuccess(user.id)
    },
    onError: (error: ErrorResponse) => {
      if (props?.onError) {
        props.onError(error)
      }
    },
  })

  return { login: mutate, isLoading }
}

export { useLogin }

import { useMutation } from 'react-query'

import { AwardsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseNewAwardsProps {
  onError?: (error: ErrorResponse) => void
}

const useNewAwards = (props?: UseNewAwardsProps) => {
  const {
    mutate: createNewAward,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
  } = useMutation(AwardsApi.newAwards, {
    onError: (error: ErrorResponse) => {
      console.error('create award error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    createNewAward,
    isCreateLoading,
    isCreateSuccess,
  }
}

export { useNewAwards }

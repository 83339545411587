import { Skeleton } from 'primereact/skeleton'
import { Avatar } from 'primereact/avatar'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useUser } from '@contexts'
import { useLogout } from '@hooks'

const StyledBtnLogout = styled(Button)`
  color: ${props => props.theme.grey} !important;
  :focus {
    box-shadow: none !important;
  }
`

const UserHeader = () => {
  const { t } = useTranslation()
  const { state: user } = useUser()
  const history = useHistory()
  const { logout } = useLogout()

  const handleLogout = () => {
    logout()
    history.replace('/login')
  }

  return (
    <nav className="bar-header">
      {!user || typeof user === 'undefined' || typeof user === 'string' ? (
        <>
          <Skeleton width="10rem" height="1rem" />
          <Skeleton shape="circle" size="2rem" />
        </>
      ) : (
        <>
          <span>{user.username}</span>
          <Avatar label={user.username.charAt(0).toUpperCase()} shape="circle" />
        </>
      )}
      <StyledBtnLogout
        icon="pi pi-sign-out"
        className="p-button-rounded p-button-secondary p-button-text"
        aria-label="Logout"
        tooltip={t('menu.logout')}
        tooltipOptions={{ position: 'bottom' }}
        onClick={() => handleLogout()}
      />
    </nav>
  )
}

export { UserHeader }

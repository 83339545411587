import { useState } from 'react'
import { Steps } from 'primereact/steps'

import { StyledContainer, StyledStepsContainer } from './components'
import { PasswordStep } from './components/PasswordStep'
import { SuccessStep } from './components/SuccessStep'
import { FailureStep } from './components/FailureStep'
import { PublicLanding } from '../../../components/PublicLanding/components'
import background from '../../../assets/images/web-background.png'

export const ResetPassword = () => {
  const [current, setCurrent] = useState(0)
  const [updateState, setUpdateState] = useState()
  const next = () => {
    setCurrent(current + 1)
  }

  const steps = [
    {
      label: '1',
      content: <PasswordStep current={current} next={next} setUpdateState={setUpdateState} />,
    },
    {
      label: '2',
      content: updateState ? <SuccessStep /> : <FailureStep />,
    },
  ]

  return (
    <StyledContainer
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <PublicLanding>
        <StyledStepsContainer>
          <Steps model={steps} activeIndex={current} readOnly />
          {steps[current].content}
        </StyledStepsContainer>
      </PublicLanding>
    </StyledContainer>
  )
}

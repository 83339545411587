import { StyledContentContainer, StyledTitle, StyledSubtitle } from '.'

export const FailureStep = () => {
  return (
    <StyledContentContainer>
      <StyledTitle className="steps-title">No hemos podido generar tu clave</StyledTitle>
      <StyledSubtitle className="confirm-pass-input">
        Hubo un problema y no hemos podido generar tu clave, intentalo nuevamente más tarde.
      </StyledSubtitle>
    </StyledContentContainer>
  )
}

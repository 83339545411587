import { ReactNode, useState } from 'react'

import { MinigolsMenu } from '../MinigolsMenu'
import { UserHeader } from '../UserHeader'

interface MinigolsLayoutProps {
  body: ReactNode
  bodyStyle?: React.CSSProperties
}

const MinigolsLayout = ({ body, bodyStyle }: MinigolsLayoutProps) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div style={{ display: 'flex' }}>
      <MinigolsMenu handleOpen={setIsOpen} />
      <div className={`container-body ${isOpen ? 'smallBody' : 'largeBoby'}`}>
        <div className="backgraund" />
        <header className="backgraund-body">
          <UserHeader />
        </header>
        <main
          style={{
            padding: '20px',

            ...bodyStyle,
          }}
        >
          {body}
        </main>
      </div>
    </div>
  )
}

export { MinigolsLayout }

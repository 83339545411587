import { Filters, PaginatedResult } from '@types'

import { api } from './axios'

interface Notification {
  id: number
  title: string
  message: string
  createdAt?: string
  date: string
  language: string
}

interface DeleteNotificarionPayload {
  notificationId: number
}

const getAll = async ({ page, limit }: Filters) => {
  const params = {
    page,
    limit,
  }
  const response = await api.get<PaginatedResult<Notification>>('/notification', { params })
  return response.data
}

const newNotification = async (payload: Notification) => {
  console.info(payload)
  const response = await api.post('/notification/schedule', payload)
  return response.data
}

const updateNotification = async (payload: Notification) => {
  const response = await api.put(`/notification/${payload.id}`, { ...payload, id: undefined })
  return response.data
}

const deleteNotification = async ({ notificationId }: DeleteNotificarionPayload) => {
  const response = await api.delete(`/notification/${notificationId}`)
  return response.data
}

export const NotificationsApi = {
  getAll,
  newNotification,
  deleteNotification,
  updateNotification,
}

import { useMutation } from 'react-query'

import { AwardsApi } from '@api'
import { ErrorResponse } from '@types'

interface UseUpdateAwardsProps {
  onError?: (error: ErrorResponse) => void
}

const useUpdateAwards = (props?: UseUpdateAwardsProps) => {
  const {
    mutate: updateAward,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useMutation(AwardsApi.updateAwards, {
    onError: (error: ErrorResponse) => {
      console.error('update award error:', error)
      if (props?.onError) {
        props.onError(error)
      }
    },
  })
  return {
    updateAward,
    isUpdateLoading,
    isUpdateSuccess,
  }
}

export { useUpdateAwards }

import { Button } from 'primereact/button'
import { Password } from 'primereact/password'
import styled from 'styled-components'

import background from '../../../../assets/images/web-background.png'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: 'url(${background})';
  min-height: 100vh;
  min-width: 100vw;
`
const StyledStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`
const StyledTitle = styled.h1`
  text-align: center;
  font-family: 'XBall Italic';
  font-size: 29px;
  margin-bottom: 2%;
  color: #fafafa;
  line-height: 150%;
  max-width: 390px;
`
const StyledSubtitle = styled.h3`
  text-align: center;
  font-family: 'Calibri' !important;
  margin-top: 8%;
  margin-bottom: 18%;
  font-size: 20px;
  color: #fafafa;
  max-width: 404px;
`
const StyledFormLabel = styled.label`
  color: #3cdcfc;
  text-align: left;
`

const StyledConfirmButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  height: 20%;
  margin-top: 10%;
  font-family: 'XBall Italic' !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  color: #878787 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  :hover,
  :active,
  :focus {
    filter: brightness(80%);
    border-color: #40a9ff !important;
  }
  &.disabled,
  :disabled {
    color: white;
    opacity: 0.3;
    background: #051efb;
    border: 1px solid #051efb !important;
    &:hover {
      filter: none;
      color: white;
      opacity: 0.3;
      background: #051efb;
      border: 1px solid #051efb !important;
    }
  }
`
const StyledInput = styled(Password)`
  &.web-input .p-inputtext {
    background: transparent !important;
    border: 0 !important;
  }
  border-bottom: 1px solid white !important;
  outline: none;
  color: white;
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`

export {
  StyledContainer,
  StyledStepsContainer,
  StyledContentContainer,
  StyledTitle,
  StyledSubtitle,
  StyledFormLabel,
  StyledConfirmButton,
  StyledInput,
}

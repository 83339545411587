import { useTranslation } from 'react-i18next'

const useMNfts = () => {
  const { t } = useTranslation()

  const mNFTs = [
    { name: t('awards.nft'), id: true },
    { name: t('awards.image'), id: false },
  ]

  return { mNFTs }
}

export { useMNfts }

import { MinigolsHeaderImg } from '../../Landing/components'

interface PublicLandingProps {
  title: string
  isLoading?: boolean
  children: React.ReactNode
}

const PublicLanding = (props: Partial<PublicLandingProps>) => {
  return (
    <>
      <header className="landing-header">
        <MinigolsHeaderImg />
      </header>
      <main>
        <h5 className="text-landing">{props.title}</h5>
        {props.children}
      </main>
    </>
  )
}

export { PublicLanding }

import { removeAuthHeader } from '@api'
import { useUser } from '@contexts'

const useLogout = () => {
  const { setState } = useUser()

  const logout = () => {
    setState(undefined)
    removeAuthHeader()
    window.localStorage.clear()
  }

  return { logout }
}

export { useLogout }
